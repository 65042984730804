<template>
    <LoadingRobot v-if="authenticating"
                  text="Waiting for 3rd party authentication"
                  secondary-text="Make sure your browser pop-ups are not blocked"/>
    <v-sheet v-else class="grid-layout-column grid-gap-lg" max-width="750" color="transparent">
        <AlertBanner v-if="error" type="error">{{ error }}</AlertBanner>
        <div v-if="provisioningConfig?.instructions" class="grid-layout-column grid-gap-lg">
            <b>Instructions</b>
            <v-sheet class="pa-4 mb-2 d-flex" color="blue-bg" rounded="lg">
                <v-icon class="align-self-start mr-2">mdi-information-outline</v-icon>
                <div>
                    {{ provisioningConfig.instructions.length > 250 && !showInstructions ? provisioningConfig.instructions.substring(0, 250)  + '...' : provisioningConfig.instructions }}

                    <a v-if="provisioningConfig?.instructionsUrl" :href="provisioningConfig.instructionsUrl" target="_blank" class="d-flex align-center">
                        <v-icon color="primary" class="mr-1">mdi-open-in-new</v-icon> {{ application.name }} Documentation
                    </a>
                </div>
                <v-icon v-if="provisioningConfig.instructions.length > 250" @click="showInstructions = !showInstructions" color="primary" class="ml-auto align-self-start">
                    {{ showInstructions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </v-sheet>
        </div>
        <v-form ref="form" v-model="formValid">
            <div v-if="Object.keys(provisioningConfig?.getConfigurationParameters()).length" class="grid-layout-column grid-gap-lg">
                <b>Parameters</b>
                <v-row v-for="(field, key) in provisioningConfig?.getConfigurationParameters()" :key="key" class="align-center my-1" no-gutters>
                    <v-col class="text-body-2 mr-4 grid-layout grid-basis-auto align-center">{{ field.label || key }} <v-icon v-if="field.encrypted" small color="green">mdi-lock</v-icon></v-col>
                    <v-col>
                        <FormTextField
                            :value="provisioningConfig?.getConfigurationParameter(key).value"
                            placeholder=""
                            @change="provisioningConfig?.setConfigurationParameter(key, $event)"
                            :rules="[ruleRequired]"
                            :type="field.encrypted ? 'password' : 'text'"
                        />
                    </v-col>
                </v-row>
            </div>
            <RestApiConfig ref="restApiConfig"
                           :value="provisioningConfig?.getRestConfig()"
                           @input="provisioningConfig?.updateRestConfig($event)"
                           onlyAuth
                           :authTypes="provisioningConfig?.importUsersMetadata?.authTypes"
            />
        </v-form>
        <div>
            <v-btn v-if="provisioningConfig.enabled" :disabled="!formValid" @click="update(application.id)" :loading="loading" elevation="0" color="primary">Update</v-btn>
            <div v-else-if="application.catalogApplicationId" class="grid-layout-column grid-gap-lg">
                <div>
                    <PopupReminder class="mb-2" :provisioningConfig="provisioningConfig"/>
                    <v-btn :disabled="!formValid" @click="submit(application.id)" :loading="loading" elevation="0" color="primary">Connect</v-btn>
                </div>
                <IntegrationHelpLinks/>
            </div>
        </div>
    </v-sheet>
</template>

<script>
    import FormTextField from '@/components/FormTextField.vue'
    import RestApiConfig from "@/components/form/RestApiConfig.vue";
    import Application from "@/lib/application/application";
    import PopupReminder from "@/views/Application/PopupReminder.vue";
    import LoadingRobot from "@/components/LoadingRobot.vue";
    import AlertBanner from "@/components/AlertBanner.vue";
    import IntegrationHelpLinks from "@/views/Application/IntegrationHelpLinks.vue";

    export default {
        components: {
            IntegrationHelpLinks,
            AlertBanner,
            LoadingRobot,
            PopupReminder,
            RestApiConfig,
            FormTextField,
        },
        props: {
            application: {
                type: Application,
                required: true
            },
        },
        data() {
            return {
                formValid: false,
                loading: false,
                error: "",
                authenticating: false,
                showInstructions: false,
            }
        },
        methods: {
            clear() {
                this.$refs?.form?.reset()
            },
            handleError(err) {
                this.$root.$emit("toast", err, "error")
                this.loading = false
                this.authenticating = false
                this.error = err
            },
            submit(appId) {
                this.loading = true
                this.error = ""
                this.$emit('error', '')

                if (this.initiateOAuthFlow) {
                    this.authenticating = true
                    return this.provisioningConfig.connectOAuth2(appId, false, () => {
                        this.application.load().then(() => {
                            if (!this.application.provisionerIsAuthorized()) {
                                this.handleError("Authentication was not successful")
                            } else {
                                this.provisioningConfig.integrate(appId).then(() => {
                                    this.$emit('completed')
                                }).catch((err) => {
                                    this.handleError("Error connecting application, please try again")
                                    this.$emit('error', err?.response?.data?.message)
                                }).finally(() => {
                                    this.application.load()
                                    this.loading = false
                                    this.authenticating = false
                                })
                            }
                        })
                    }).catch((error) => {
                        this.authenticating = false
                        this.handleError(error?.message)
                    })
                } else {
                    return this.provisioningConfig.integrate(appId).then(() => {
                        this.$emit('completed')
                    }).catch((err) => {
                        this.handleError("Error connecting application, please try again")
                        this.$emit('error', err?.response?.data?.message)
                    }).finally(() => {
                        this.application.load()
                        this.loading = false
                    })
                }
            },
            update(appId) {
                this.submit(appId)
            },
            validate() {
                return this.$refs.form.validate()
            }
        },
        computed: {
            provisioningConfig() {
                return this.application?.getProvisioningConfig() || this.application.globalApplicationProvisioningConfig
            },
            initiateOAuthFlow() {
                return this.provisioningConfig.authType === "oauth2" && this.provisioningConfig.authConfigOAuth2?.grantType === "authorizationCode"
            }
        },
    }


</script>
